import React, { useState, useEffect } from 'react';
import SalatoAPI from '../../helpers/SalatoAPI';
import Cookies from 'js-cookie';
import Loading from '../../components/Loading';
import {parseISO, format} from 'date-fns';
import { CELMask } from '../../components/Mask';
import { 
            PageArea
        } from './styled';

let Timer = null;
const Clientes = (props) => {
    const API = SalatoAPI();
    const [DsSearch, setDsSearch] = useState('');
    const [Clientes, setCliente] = useState([]);
    const [loading, setLoading] = useState(false);
    const [TpLocalizacao, setTpLocalizacao] = useState('P')
    const [DsPlaceHolder, setDsPlaceHolder] = useState('Digite um cliente para pesquisa');
    const [Loja, setLoja] = useState('');

    const getClientes = async (e) =>{
        setCliente([]);
        e.preventDefault();
        let jwt = Cookies.get('token');
        setLoading(true);

        setTimeout(async()=>{
            if (TpLocalizacao === 'P'){
                const json = await API.getInfoCliente(
                    DsSearch,
                    jwt
                )
                if (!json.error){
                    setCliente(json.Pessoa);
                }
            }else{
                const json = await API.getInfoTelefone(
                    DsSearch,
                    jwt
                )
                if (!json.error){
                    setCliente(json.Pessoa);
                }
            }

            setLoading(false);
        },1000);
    }   

    const Mascara = (Seach) => {
        if (TpLocalizacao ==='P'){
            return Seach;
        }else{
            return CELMask(Seach);
        }
    }

    const handleLoja = (i) =>{
        if (i ==='M'){
            return "Magé";
        }else if( i === 'P'){
            return "Piabeta";
        }else{
            return "Internet";
        }
    }

    useEffect(()=>{
        if (TpLocalizacao ==='P'){
            setDsPlaceHolder('Digite um cliente para pesquisa');
        }else{
            setDsPlaceHolder('Digite um telefone para pesquisa')
        }
    },[TpLocalizacao]);

    const handleData = (i) => {
        if (i === null){
            return ""
        }else{
            console.log(i)
            let x = parseISO(i);
            return format(x,FORMAT );
        }
    }

    const FORMAT = 'dd/LL/yyyy';

    return(
        <PageArea>
            <div className="AreaPesquisa">
                <form onSubmit={getClientes}>
                    <input 
                        value={Mascara(DsSearch)}
                        onChange={e=>setDsSearch(e.target.value)}
                        type="text"
                        placeholder={DsPlaceHolder}
                    />
                     <select type="text" value={TpLocalizacao} onChange={e=>setTpLocalizacao(e.target.value)}>
                        <option className="AreaOption" value='P' placeholder="Selecione um campo de localizacao">Localizar pelo Nome</option>
                        <option className="AreaOption" value='T' placeholder="Selecione um campo de localizacao">Localizar pelo Telefone</option>
                    </select>
                    <button>Pesquisar</button>
                </form>
            </div>
            <div className="AreaListaCliente">
                <div className="AreaListaCliente AreaListaCliente--AreaItems">
                    <div className="AreaListaCliente AreaListaCliente--Topo">
                        <h3>Cadastro de Clientes</h3>
                    </div>
                    <div className="AreaLista">
                        {loading &&
                            <div className="AreaLoanding">
                                 <Loading color="#00008B"/>
                            </div>
                        }

                        {Clientes.length !== 0 ?
                            Clientes.map((i,k)=>
                            <div className="ListaClientes" >
                                <div className="ListaClientes--AreaNmPessoa">{i.NmPessoa}</div>
                                <div className="ListaClientes ListaClientes--AreaButton">
                                    <div className="AreaLoja">{handleLoja(i.Loja)}</div>
                                    <div className="AreaData">{handleData(i.DtNascimento)}</div>
                                    <div className="AreaEmail">{i.DsLogin}</div>
                                    <div className="AreaTelefone">{i.DsFaxCobranca}</div>
                                    <div>
                                        <img src={require('../../assets/images/editar.png')}/>
                                    </div>
                                </div>
                            </div>
                        ):''}
                    </div>
                </div>
            </div>
        </PageArea>
    )
}

export default Clientes;